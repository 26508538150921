import './App.css';

function App() {
  return (
    <div className="row align-items-center min-vh-100">
      <div className="col text-center justify-content-center d-flex">
        <div className="card content border-0">
          <div className="card-header bg-transparent border-4">
            <h5 className="fw-light m-0 p-1">Under Construction</h5>
          </div>

          <div className="card-body">
            <p>Welcome, {window.location.hostname} is currently under construction.</p>

            <p>Check back soon for more updates!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;